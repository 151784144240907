import React from 'react'
import { Link } from 'react-router-dom'

import WelcomeImage from '../../data/cases/eq/media/welcome/welcome.jpg'
// import WelcomeImage from '../../data/cases/mollusck/media/welcome/welcome.jpg'

const Welcome = () => {
  return (
    <>
      <Link to="/case/yesterday">
        <img src={WelcomeImage} style={{ width: '95vw' }} />
        <img src={WelcomeImage} style={{ width: '95vw' }} />
        <div style={{ textAlign: 'center', textDecoration: 'none !important' }}>
          click click click
        </div>
      </Link>
    </>
  )
}

export default Welcome
