import case001 from './001-the-mollusck/main.json'
import demo from './demo'
import eq from './eq/main.json'
import yesterday from './yesterday/main.json'

const cases = {
  demo: demo,
  mollusck: case001,
  eq: eq,
  yesterday: yesterday,
}

export default cases
