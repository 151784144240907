import montrealMap from './map-montreal.jpg'
import plateauMap from './map-plateau.jpg'
import plateauWhiteMap from './map-plateau-white.jpg'
import plateauBlackMap from './map-plateau-black.jpg'
import plateauMapPlaceholder from './map-plateau-placeholder.jpg'

const maps = {
  main: {
    name: 'Montreal',
    map: montrealMap,
    width: 350,
    buffer: 0.0005,
    haloPadding: 40,
    coords: {
      north: 45.529993,
      south: 45.506051,
      east: -73.560397,
      west: -73.59179,
    },
  },
  details: [
    {
      name: 'WHITE-Plateau',
      hideNameInMap: false,
      map: plateauWhiteMap,
      placeholder: plateauMapPlaceholder,
      width: 350,
      buffer: 0.0005,
      haloPadding: 0.1015,
      // haloBGColor: 'red',
      coords: {
        north: 45.527689,
        south: 45.513505,
        east: -73.571556,
        west: -73.591628,
      },
    },
    {
      name: 'BLACK-Plateau',
      hideNameInMap: false,
      map: plateauBlackMap,
      // placeholder: plateauMapPlaceholder,
      width: 350,
      buffer: 0.0005,
      haloPadding: 0.1015,
      // haloBGColor: 'red',
      coords: {
        north: 45.527689,
        south: 45.513505,
        east: -73.571556,
        west: -73.591628,
      },
    },
  ],
}

export default maps
